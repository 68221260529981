
html {
  font-size: 62.5%; // Sets 1rem = 10px by default
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(96.8deg, #F2942F 21.96%, #ED6E18 69.49%);
  font-size: 1.4rem;
  box-sizing: border-box;
  color: #0d0d0d;
}

/**
    Default resets
 */
button,
a,
input,
textarea {
  box-shadow: none;
  outline: none;
  border: none;
  text-decoration: none;
  color: inherit;
  background: transparent;

  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
    border: none;
    text-decoration: none;
    color: inherit;
  }
}

button,
a {
  cursor: pointer;
}