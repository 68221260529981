@import '../../styles/colors';
@import '../../styles/fonts';

.button {
    font-family: $fontPrimary;
    font-style: normal;
    height: 5rem;
    border-radius: 2.5rem;
    display: inline-block;
    padding: 0 2rem;
    font-weight: $fontWeightBold;
    font-size: 1.6rem;
    cursor: pointer;
    transition: 0.5s;
    background: linear-gradient(90deg, $buttonC1, $buttonC2 51%, $buttonC1) var(--x, 0) / 200%;

    &:hover {
        --x: 100%;
    }

    &.primary {
        color: $colorWhite;
        box-shadow: 2 0 20px #eee;

        &:active,
        &:focus {
            background: $colorGrenadier;
        }
    }

    &.secondary {
        color: $colorFlamingo;
        background: white;
        border: solid 2px $colorFlamingo;
        padding: 1rem;

        &:hover,
        &:active,
        &:focus {
            border: solid 2px $colorFlamingo;
            background: white;
            color: $colorGrenadier;
        }
    }

    &.block {
        width: 100%;
        display: block;
    }

    &.small {
        height: 4rem;
        border-radius: 2rem;
        font-size: 1.4rem;
    }

    &.superSmall {
        height: 3rem;
        border-radius: 1.5rem;
        font-size: 1.4rem;
        padding: 0 1rem;
    }

    &.large {
        padding: 0 5rem;
    }

    &.round {
        width: 5rem;
        padding: 0;

        &.small {
            width: 4rem;
            padding: 0;
        }

        &.superSmall {
            width: 3rem;
            padding: 0;
        }
    }

    &.withLeftIcon {
        &>svg {
            margin-right: 0.8rem;
        }
    }

    &.withRightIcon {
        &>svg {
            margin-left: 0.8rem;
        }
    }

    &.disabled {
        background: $colorAlto;
        color: $colorButtonDisabled !important;
        border: none;
        cursor: default;

        &:hover,
        &:active,
        &:focus {
            background: $colorAlto;
            color: $colorButtonDisabled;
            border: none;
            cursor: default;
        }
    }

    &.noWrap {
        white-space: nowrap;
    }

    &.outlined {
        color: black;
        font-weight: normal;
        border-radius: 1rem;
        border: 1px solid;
        background-color: transparent;

        &.primary {
            border-color: $colorFlamingo;

            &:hover,
            &:active,
            &:focus {
                background: $colorWildSand;
                color: $colorGrenadier;
                border-width: 1px;
            }
        }

        &.secondary {
            border-color: $colorSilverChalice;

            &:hover,
            &:active,
            &:focus {
                background: $colorWildSand;
                border: 1px solid $colorFlamingo;
                color: black;
            }
        }

        &.disabled {
            color: $colorSilverChalice;
            border-color: $colorSilverChalice;

            &:hover,
            &:active,
            &:focus {
                color: $colorSilverChalice;
                border-color: $colorSilverChalice;
                border: 1px solid $colorSilverChalice;
            }
        }
    }

    &.curvy {
        border-radius: 5rem;
    }

    /**
        In case of icons
     */

    &>* {
        vertical-align: middle;
    }

    &>svg,
    &>img {
        width: 1.6rem;
        display: inline-block;
    }
}