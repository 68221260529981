@import '../../styles/colors';

.headline {
    color: $colorCodGray;
    margin: 0;
    display: block;
}

.level-1 {
    font-family: Lato;
    font-size: 2.5rem;
    line-height: 4rem;
}

.level-2 {
    font-family: Lato;
    font-size: 2rem;
    line-height: 3.5rem;
}

.level-3 {
    font-family: Lato !important;
    font-size: 1.6rem;
    line-height: 2.5rem;
}

.level-4 {
    font-size: 1.4rem;
    line-height: 2rem;
}

.center {
    text-align: center;
}
