@import 'styles/rwd';
@import 'styles/colors';

.App {
  // Spacing
  padding: 0px;
  margin: 0px;

  box-sizing: inherit;

}


.AppHeader {
  // Spacing
  padding: 00.7em;

  // Theme
  background: $colorWhite;
  box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.07);

  // texte alignement
  text-align: center;
  justify-content: center;
  align-items: center;

  .AppLogo {
    // Sizing
    height: 3em;
    width: auto;
    
    pointer-events: none;

    @include media('<=phone') {
      height: 2em;
    }
  }
}

.userChoice {
  // Sizing
  width: 100%;
  margin: 1em auto;

  @include media('<=phone') { margin-bottom: 0px; }
  @include media('>phone') {
    max-width: 90vw;
    margin: 2em auto;
  }
  
  @include media('>tablet') { margin: 3em auto; }
}

.emailPreferences {
  // Spacing
  padding: 3.5em 6em;
  margin: 0px auto;

  // Sizing
  max-width: 780px;

  // Theme
  background: $colorWhite;
  border: 1px solid #D9D9D9;
  border-radius: 20px;

  /* Font styling */
  color: $colorDisabledGray;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  line-height: 25px;

  @include media('<=tablet') {
    padding: 1em 1.5em;
    border-radius: 12px;
  }

  @include media('<=phone') {
    padding: 1em 1.75em;
    border-radius: 0px;
  }
}

.notif {
  position: sticky;
  top: 0px;
  width: 100%;
  margin: 0px;
  text-align: center;
  font-size: 1.2em;
}

.notifSuccessMessage {
  color: black;
  background-color: $colorChristi;
}

.notifWrarningMessage {
  color: black;
  background-color: $colorWarning;
}

.notifErrorMessage {
  color: black;
  background-color: $colorRedOrange;
}