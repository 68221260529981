@import '../../styles/colors';

.container {
    cursor: pointer;
    display: inline-block;
}

.wrapper {
    display: flex;
}
.trackerWrapper {
    display: flex;
    align-items: center;
}
.radio {
    display: none;

    &:checked + .mask {
        border-color: $colorChristi;

        &::after {
            content: '';
            position: absolute;
            width: 0.8rem;
            height: 0.8rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: $colorChristi;
            border-radius: 50%;
        }
    }
}

.mask {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid $colorAlto;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    transition: 0.2s;
    vertical-align: middle;

    &.error {
        border-color: $colorRedOrange;
    }
    &.hidden {
        display: none;
    }
}

.labelText {
    font-size: 1.4rem;
    vertical-align: middle;
    color: $colorDoveGray;
    &.trackerChecked {
        color: black;
    }
    &.checked {
        color: $colorFlamingo;
    }
}
