@import '../../styles/colors';

.languageFieldset {
    border: none;
    margin: 0px;
    padding: 20px 0px;

    legend {
        color: $colorMuteGray;
    }
}

.radio {
    margin-bottom: 1.5rem;
    display: block;
    div span {
        color: black;
    }
}
