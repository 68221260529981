@import '../../styles/colors';
@import '../../styles/fonts';

.link {
    color: $colorFlamingo;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        color: $colorRedOrange;
    }

    &:active,
    &:visited {
        color: $colorFlamingo;
    }
}

.center {
    text-align: center;
}

.block {
    display: block;
}

.bold {
    font-family: Lato !important;
    font-weight: $fontWeightBold;
}

.white {
    color: $colorWhite;
    text-decoration: underline;

    &:active,
    &:visited {
        color: $colorWhite;
    }
}

.muted {
    color: $colorMuteGray;
    &:hover {
        color: $colorDoveGray;
    }
}
