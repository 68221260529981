@import '~include-media/dist/include-media';
@import '~include-media-export/dist/include-media-export';

$breakpoints: (
    phone: 440px,
    tablet: 768px,
    desktop: 1024px,
    whitelabeltext: 990px,
    whitelabelimg: 855px,
);

@mixin phoneHidden {
    @include media('<=phone') {
        display: none;
    }
}
